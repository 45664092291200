<template>
  <Skeleton v-if="isLoading" class="py-6 mb-6" />
  <div v-else class="mt-5 px-sm-3">
    <div class="w-100 wrapper-jadwal-kerja">
      <div v-if="dataScheduleWorker.length < 1">
        <DataNotFound />
      </div>
      <div class="columns" v-else>
        <div class="column is-3 border-right">
          <div class="mb-5 attention-tukang-mobile mx-4 mx-sm-0">
            <p class="averta-bold">Tentukan minimal 1 hari kerja per tukang</p>
            <p>
              Jadwal kerja pertama harus terisi bekerja. Setiap skill tukang
              harus memiliki minimal 1 hari kerja
            </p>
          </div>
          <span class="is-size-5 averta-bold mx-4 mx-sm-0">Pilih Area</span>
          <div class="area-wrapper mt-4 mx-sm-0">

            <!-- sliceArea1 -->
            <div v-if="sliceArea1.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea1" :key="`${item.area_id}-area-1`" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap white-space-sm-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea1 -->

            <!-- sliceArea2 -->
            <div v-if="sliceArea2.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea2" :key="`${item.area_id}-area-2`" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap white-space-sm-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea2 -->

            <!-- sliceArea3 -->
            <div v-if="sliceArea3.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea3" :key="`${item.area_id}-area-3`" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap white-space-sm-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea3 -->

          </div>
        </div>
        <div class="column is-9">
          <div class="mb-2 attention-tukang-web">
            <p class="averta-bold">Tentukan minimal 1 hari kerja per tukang</p>
            <p>
              Jadwal kerja pertama harus terisi bekerja. Setiap skill tukang harus memiliki minimal 1 hari kerja
            </p>
          </div>
          <ErrorComponent v-if="messageJadwalPekerjaan !== ''" :title="messageJadwalPekerjaan" class="mb-4 inline-flex" />
          <div class="wrapper-table mb-6">
            <p class="averta-bold mb-4 is-size-5">Konsultan Pengawas</p>
            <table class="table is-bordered my-table">
              <tr>
                <td :colspan="dataScheduleWorker[0].components[0].workers[0].schedule.length + 1" class="has-text-centered py-4">
                  Hari Kerja
                </td>
              </tr>
              <tr class="has-text-centered">
                <td></td>
                <td v-for="days, indexDays in dataScheduleWorker[0].components[0].workers[0].schedule" :key="`${days.day}-${indexDays}`">
                  {{ days.day }}
                </td>
              </tr>
              <tr class="background-konsultan">
                <td v-if="dataScheduleWorker[0].components[0].workers[0].skill_image">
                  <div class="is-flex is-align-items-center wrapper-workers">
                    <img :src="dataScheduleWorker[0].components[0].workers[0].skill_image" class="image-skill mr-3"
                    />
                    <div>
                      <p class="averta-bold">
                        {{ dataScheduleWorker[0].components[0].workers[0].skill_name }}
                      </p>
                      <p>
                        {{ dataScheduleWorker[0].components[0].workers[0].max === "Optional" ? dataScheduleWorker[0].components[0].workers[0].max : `Maks: ${dataScheduleWorker[0].components[0].workers[0].max} Hari`}}
                      </p>
                    </div>
                  </div>
                </td>
                <td v-for="dataSchedule, indexSchedule in dataScheduleWorker[0].components[0].workers[0].schedule" :key="`${dataSchedule.day}-${indexSchedule}`" class="has-text-centered">
                  <b-dropdown aria-role="list" class="mb-4 has-text-left" v-model="dataSchedule.selected">
                    <template #trigger="{ active }">
                      <b-button :label="dataSchedule.selected !== null ? dataSchedule.selected : 'Pilih sesi'" :icon-right="active ? 'menu-up' : 'menu-down'" :class="{backgroundDropdown: dataSchedule.selected !== null, backgroundDropdownBooking: dataSchedule.selected === 'Booking' }"
                      />
                    </template>

                    <b-dropdown-item aria-role="listitem" v-for="dataOption, indexOption in dataSchedule.option" :key="`${dataOption}-${indexOption}`" :value="dataOption" @click="setDataScheduleTemp()">
                      {{ dataOption }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </table>
          </div>
          <div v-for="(dataArea, indexArea) in dataScheduleWorker" :key="`${dataArea.area_name}-${indexArea}`">
            <div class="tabs tabs-area-wrapper thin-scroll tabs-component-wrapper user-select-text" v-if="area === dataArea.area_id">
              <ul v-if="sliceComponent1.length > 0">
                <li v-for="(dataComponents, indexComponent) in sliceComponent1" :key="`${dataComponents.component_name}-component-1`" :class="{'is-active averta-bold': currentClick === dataComponents.component_id}">
                  <a @click="handleClick(dataComponents.component_id)">
                    <div class="is-flex is-align-items-center">
                      <span>{{ dataComponents.component_name }}</span>
                      <div v-if="handleValidateForm(indexArea, indexComponent) && showError" class="ml-2">
                        <AlertIcon />
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
              <ul v-if="sliceComponent2.length > 0">
                <li v-for="dataComponents in sliceComponent2" :key="`${dataComponents.component_name}-component-2`" :class="{'is-active averta-bold': currentClick === dataComponents.component_id}">
                  <a @click="handleClick(dataComponents.component_id)">
                    <div class="is-flex is-align-items-center">
                      <span>{{ dataComponents.component_name }}</span>
                      <div v-if="handleValidateForm(indexArea, indexComponent) && showError" class="ml-2">
                        <AlertIcon />
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
              <ul v-if="sliceComponent3.length > 0">
                <li v-for="dataComponents in sliceComponent3" :key="`${dataComponents.component_name}-component-3`" :class="{'is-active averta-bold': currentClick === dataComponents.component_id}">
                  <a @click="handleClick(dataComponents.component_id)">
                    <div class="is-flex is-align-items-center">
                      <span>{{ dataComponents.component_name }}</span>
                      <div v-if="handleValidateForm(indexArea, indexComponent) && showError" class="ml-2">
                        <AlertIcon />
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div v-for="(dataComponents, indexComponent) in dataArea.components" :key="`${dataComponents.component_name}-content`">
              <div v-if="currentClick === dataComponents.component_id && area === dataArea.area_id">
                <div class="wrapper-table">
                  <p class="averta-bold mb-4 is-size-5">Kerja Mitra Tukang</p>
                  <table class="table is-bordered mb-5 my-table">
                    <tr>
                      <td :colspan="dataComponents.workers[0].schedule.length + 2" class="has-text-centered py-4">
                        Hari Kerja
                      </td>
                    </tr>
                    <tr>
                      <td colspan="1" class="averta-bold color-red">
                        <div class="is-flex is-align-items-center is-clickable" v-if="seeCombine.on === true" @click="closeCombine()">
                          <b-icon icon="close mr-3"></b-icon>
                          <p>Tutup Kombinasi</p>
                        </div>
                      </td>
                      <td v-for="days, indexDays in dataComponents.workers[0].schedule" :key="`${days.day}-${indexDays}`" class="has-text-centered">
                        {{ days.day }}
                      </td>
                    </tr>
                    <tr v-for="(dataWorker, indexWorker) in dataComponents.workers" :key="`${dataWorker.skill_id}-${indexWorker}`" :style="{ border: seeCombine.on === true && seeCombine.color === dataWorker.group_color ? `2px solid ${seeCombine.color}` : '', background: seeCombine.on === true && seeCombine.color === dataWorker.group_color ? `${seeCombine.rgba}` : '' }">
                      <!-- <td v-if="!dataWorker.note_item_working && dataWorker.skill_id != idKonsultanPengawas"
                        style="position: relative"
                        :class="{'has-background-light': modeEditGroup.on === true && dataWorker.group_color !== modeEditGroup.current_color && dataWorker.checked === true }">
                        <div v-if="seeCombine.on === true && seeCombine.color !== dataWorker.group_color" class="mt-4 is-clickable">
                            <GroupingIcon color="grey" />
                        </div>
                        <div v-else-if="modeEditGroup.on === false">
                          <b-tooltip position="is-top" type="is-dark" :triggers="['hover']" :auto-close="true" v-if="!dataWorker.group_color">
                            <template v-slot:content>
                              <div class="is-flex is-align-items-center" style="margin-left: 195px;">
                                <i class="mdi mdi-information mr-2"></i>
                                <p>Gabungkan Keahlian Untuk 1 Mitra</p>
                              </div>
                            </template>
                            <div>
                              <input
                                type="checkbox"
                                class="mt-4"
                                style="width: 20px; height: 20px"
                                v-model="dataWorker.checked"
                                @change="addGroup(dataArea.area_id, dataComponents.component_id, dataWorker.detail_component_id, dataWorker.skill_id, dataWorker.checked, dataWorker.skill_name, dataWorker.skill_image, dataWorker.item_working, dataWorker.worker_index)"/>
                            </div>
                          </b-tooltip>
                          <div
                            v-else
                            class="mt-4 is-clickable"
                            @click="seeCombine.on === false ? editGrouping(indexWorker, dataArea.area_id, dataComponents.component_id, dataWorker.detail_component_id, dataWorker.item_working) : ''">
                            <GroupingIconFilled :color="dataWorker.group_color" v-if="(editGroup.modal === true && editGroup.index_worker === indexWorker && editGroup.area_id === dataArea.area_id && editGroup.component_id === dataComponents.component_id && editGroup.detail_component_id === dataWorker.detail_component_id && editGroup.item_working === dataWorker.item_working) || (seeCombine.on && seeCombine.color === dataWorker.group_color)" />
                            <GroupingIcon :color="dataWorker.group_color" v-else />
                          </div>
                          <div v-if="editGroup.modal === true && editGroup.index_worker === indexWorker && editGroup.area_id === dataArea.area_id && editGroup.component_id === dataComponents.component_id && editGroup.detail_component_id === dataWorker.detail_component_id && editGroup.item_working === dataWorker.item_working " class="edit-group mb-6">
                            <div class="header-edit-kombinasi p-2 is-flex is-align-items-center is-justify-content-space-between">
                              <p class="averta-bold">Kombinasi Keahlian</p>
                              <button type="button" class="delete" @click="hideGroup()"/>
                            </div>
                            <div class="p-2 is-clickable is-flex is-align-items-center" @click="ubahGrouping(dataWorker.group_color)">
                              <EditIcon class="mr-3" />
                              <p>Ubah Keahlian</p>
                            </div>
                            <div class="p-2 is-clickable is-flex is-align-items-center" @click="lihatKombinasi(dataWorker.group_color)">
                              <EyeIcon class="mr-3" />
                              <p>Lihat Keahlian</p>
                            </div>
                            <div class="p-2 is-clickable is-flex is-align-items-center" @click="removeCombine(dataWorker.group_color)">
                              <UngroupIcon class="mr-3" />
                              <p>Lepaskan Keahlian</p>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div v-if="dataWorker.group_color === modeEditGroup.current_color || dataWorker.group_color === null">
                            <input
                              type="checkbox"
                              class="mt-4"
                              style="width: 20px; height: 20px"
                              v-model="dataWorker.checked"
                              @change="changeGroup(dataArea.area_id, dataComponents.component_id, dataWorker.detail_component_id, dataWorker.skill_id, dataWorker.checked, dataWorker.skill_name, dataWorker.skill_image, dataWorker.item_working, dataWorker.group_color, dataWorker.worker_index)"/>
                          </div>
                          <div v-else class="mt-4 is-clickable">
                            <GroupingIcon color="grey" />
                          </div>
                        </div>
                      </td> -->
                      <td v-if="dataWorker.note_item_working" :colspan="dataComponents.workers[0].schedule.length + 2" class="background-td py-4">
                        <p>Item Pekerjaan</p>
                        <p class="averta-bold ellipsis-3">
                          {{ dataWorker.note_item_working }}
                        </p>
                      </td>
                      <td v-else-if="dataWorker.skill_id != idKonsultanPengawas" id="td-relation">
                        <div class="is-flex is-align-items-center wrapper-workers">
                          <img :src="dataWorker.skill_image" class="image-skill mr-3" />
                          <div>
                            <p class="averta-bold">
                              {{ dataWorker.skill_name }}
                            </p>
                            <p>
                              {{ dataWorker.max === "Optional" ? dataWorker.max : `Maks: ${dataWorker.max} Hari` }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td v-if="dataWorker.skill_id == idKonsultanPengawas" style="display: none"></td>
                      <td v-else v-for="dataSchedule, indexSchedule in dataWorker.schedule" :key="`${dataSchedule.day}-${indexSchedule}`" class="has-text-centered">
                        <div v-if=" isAllEqualSchedule(dataWorker.schedule) && showError && indexWorker !== 0">
                          <p style="color: red">!</p>
                        </div>
                        <b-dropdown aria-role="list" class="mb-4 has-text-left" v-model="dataSchedule.selected">
                          <template #trigger="{ active }">
                            <b-button :label="dataSchedule.selected !== null ? dataSchedule.selected : 'Pilih sesi' " :icon-right="active ? 'menu-up' : 'menu-down'" :class="{ backgroundDropdown: dataSchedule.selected !== null, backgroundDropdownBooking: dataSchedule.selected === 'Booking'}"
                            />
                          </template>

                          <div v-if="!handleMaxDisabled(dataWorker.day, dataSchedule.day, dataWorker.max)">
                            <b-dropdown-item @click="handleMaxDay(indexArea, indexComponent, indexWorker, dataWorker.max, dataWorker.schedule)" aria-role="listitem" v-for="dataOption, indexOption in dataSchedule.option" :key="`${dataOption}-${indexOption}`" :value="dataOption">
                              {{ dataOption }}
                            </b-dropdown-item>
                          </div>
                          <div v-else>
                            <b-dropdown-item @click="handleMaxDay(indexArea, indexComponent, indexWorker, dataWorker.max, dataWorker.schedule)" aria-role="listitem" value="Tersedia">
                              Tersedia
                            </b-dropdown-item>
                            <b-dropdown-item @click=" handleMaxDay(indexArea, indexComponent, indexWorker, dataWorker.max, dataWorker.schedule)" aria-role="listitem" value="Booking">
                              Booking
                            </b-dropdown-item>
                          </div>
                        </b-dropdown>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="floating-grouping" v-if="group.group.length > 0 && floatingGroup === true">
      <div class="floating-top">
        <img :src="require('@/assets/img/wave.png')" alt="wave" class="wave" />
        <div class="is-flex is-align-items-center">
          <GroupingSkill class="grouping-icon mr-4" />
          <div>
            <p class="averta-bold">
              <span class="is-size-4 mr-1">{{ group.group.length }}</span>
              keahlian terpilih
            </p>
            <p>
              Keahlian terpilih akan dikelompokan dan dikerjakan oleh 1 mitra
              yang sama
            </p>
          </div>
        </div>
      </div>
      <div class="floating-bottom is-flex is-align-items-center is-justify-content-center">
        <div class="wrapper-skill is-flex-grow-1 mr-3 is-flex">
          <div class="is-flex is-flex-shrink-0" v-for="(item, index) in group.group" :key="`${index}-group`">
            <div>
              <p class="has-text-grey mb-2">{{ item.note_item_working }}</p>
              <div class="is-flex is-align-items-center">
                <img :src="item.skill_image" alt="alt" class="mr-3" width="30"/>
                <p class="averta-bold color-red">{{ item.skill_name }}</p>
              </div>
            </div>
            <div class="is-flex is-align-items-center mx-4" v-if="index !== group.group.length - 1">
              <GroupingRelation />
            </div>
          </div>
        </div>
        <button class="btn-grouping is-flex is-align-items-center is-clickable" :class="{ active: group.group.length > 1 }" :disabled="group.group.length < 2" @click="postGroup()">
          <p class="mr-3 averta-bold">Satukan Keahlian</p>
          <ArrowRightIconLong />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Skeleton from '@/components/skeleton/Skeleton'
import DataNotFound from '@/components/DataNotFound'
import ErrorComponent from '@/components/ErrorComponent.vue'
import imageDeleteGroup from '@/assets/img/delete-area-component.png'
import {
  AlertIcon,
  GroupingIcon,
  GroupingIconFilled,
  GroupingSkill,
  ArrowRightIconLong,
  GroupingRelation,
  EditIcon,
  EyeIcon,
  UngroupIcon
} from '@/components/icons'

export default {
  name: 'JadwalPekerjaan',
  props: ['messageJadwalPekerjaan', 'showError', 'idSurveyUpdateItemPekerjaan'],
  components: {
    Skeleton,
    DataNotFound,
    ErrorComponent,
    AlertIcon,
    // eslint-disable-next-line vue/no-unused-components
    GroupingIcon,
    // eslint-disable-next-line vue/no-unused-components
    GroupingIconFilled,
    GroupingSkill,
    ArrowRightIconLong,
    GroupingRelation,
    // eslint-disable-next-line vue/no-unused-components
    EditIcon,
    // eslint-disable-next-line vue/no-unused-components
    EyeIcon,
    // eslint-disable-next-line vue/no-unused-components
    UngroupIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    }),
    countColspan () {
      if (this.seeCombine.on === false) {
        return 2
      }
      return 1
    }
  },
  data () {
    return {
      isLoading: false,
      area: null,
      selectedArea: null,
      dataScheduleWorker: [],
      validateForm: [],
      idKonsultanPengawas: process.env.VUE_APP_KONSULTAN_PENGAWAS_ID,
      group: {
        group_color: null,
        group: []
      },
      floatingGroup: false,
      editGroup: {
        modal: false,
        index_worker: null,
        area_id: null,
        component_id: null,
        detail_component_id: null,
        item_working: null
      },
      modeEditGroup: {
        on: false,
        current_color: null
      },
      seeCombine: {
        on: false,
        color: null,
        rgba: null
      },
      addGroupMode: false,
      dataScheduleTemp: [],

      currentClick: 1,
      sliceArea1: [],
      sliceArea2: [],
      sliceArea3: [],
      sliceComponent1: [],
      sliceComponent2: [],
      sliceComponent3: []
    }
  },
  methods: {
    changeIsActive (data) {
      this.selectedArea = data
      this.area = data.area_id
      this.currentClick = data.components[0].component_id
      this.sliceAreaOrComponent('component', data.components)
    },
    capitalizeText (text) {
      let capitalizedString = text.substr(0, 1).toUpperCase()
      capitalizedString += text.replace(text.substr(0, 1), '')
      return capitalizedString
    },
    handleClick (value) {
      this.currentClick = value
    },
    sliceAreaOrComponent (type, array) {
      const result = []
      if (array.length > 13 && array.length < 19) {
        // Bagi menjadi dua bagian
        const middleIndex = Math.ceil(array.length / 2)
        result.push(array.slice(0, middleIndex))
        result.push(array.slice(middleIndex))
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = result[1]
          this.sliceArea3 = []
        } else if (type === 'component') {
          this.sliceComponent1 = result[0]
          this.sliceComponent2 = result[1]
          this.sliceComponent3 = []
        }
      } else if (array.length >= 19) {
        // Bagi menjadi tiga bagian
        const firstSplitIndex = Math.ceil(array.length / 3)
        const secondSplitIndex = 2 * firstSplitIndex
        result.push(array.slice(0, firstSplitIndex))
        result.push(array.slice(firstSplitIndex, secondSplitIndex))
        result.push(array.slice(secondSplitIndex))
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = result[1]
          this.sliceArea3 = result[2]
        } else if (type === 'component') {
          this.sliceComponent1 = result[0]
          this.sliceComponent2 = result[1]
          this.sliceComponent3 = result[2]
        }
      } else {
        // Jika kurang dari atau sama dengan 13, tidak perlu membagi
        result.push(array)
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = []
          this.sliceArea3 = []
        } else if (type === 'component') {
          this.sliceComponent1 = result[0]
          this.sliceComponent2 = []
          this.sliceComponent3 = []
        }
      }
    },
    getDataJadwalPekerjaan () {
      this.isLoading = true
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId ? this.$route.params.surveyId : this.idSurveyUpdateItemPekerjaan
      }
      this.$store
        .dispatch('projectV2/getDataJadwalPekerjaan', payload)
        .then((response) => {
          this.isLoading = false
          this.dataScheduleWorker = response.data.data

          // Restore selected schedule first
          if (this.dataScheduleTemp.length > 0) {
            this.dataScheduleTemp.forEach((itemArea, indexArea) => {
              itemArea.components.forEach((itemComponent, indexComponent) => {
                itemComponent.workers.forEach((itemWorkers, indexWorkers) => {
                  if (!itemWorkers.note_item_working) {
                    itemWorkers.schedule.forEach((itemSchedule, indexSchedule) => {
                      this.dataScheduleWorker[indexArea].components[indexComponent].workers[indexWorkers].schedule[indexSchedule].selected = itemSchedule.selected
                    })
                  }
                })
              })
            })
          }

          // Validate selected schedule
          this.dataScheduleWorker.map((itemArea, indexArea) => {
            itemArea.components.map((itemComponent, indexComponent) => {
              itemComponent.workers.map((itemWorkers, indexWorkers) => {
                if (!itemWorkers.note_item_working && indexWorkers > 0) {
                  // if user already fill the form and want to edit selected sesi
                  const isEqual = this.isAllEqualSchedule(itemWorkers.schedule)

                  // if not selected
                  if (isEqual) {
                    this.dataScheduleWorker[indexArea].components[
                      indexComponent
                    ].workers[indexWorkers].day = []
                  } else {
                    // if already selected, recheck validation
                    this.handleMaxDay(indexArea, indexComponent, indexWorkers, itemWorkers.max, itemWorkers.schedule)
                  }
                }
              })
            })
          })

          this.handleEqualSchedule(response.data.data)
          this.area = response.data.data[0].area_id
          this.selectedArea = response.data.data[0]

          this.currentClick = response.data.data[0].components[0].component_id
          this.sliceAreaOrComponent('area', response.data.data)
          this.sliceAreaOrComponent('component', response.data.data[0].components)

          this.$store.commit(
            'project/setDataJadwalPekerjaan',
            response.data.data
          )
        })
        .catch((error) => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data jadwal kerja, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    changeSchedule (
      dataAreaName,
      dataComponentName,
      dataSkillName,
      detailComponentId,
      dataScheduleDay,
      index
    ) {
      const findArea = this.dataScheduleWorker.find(
        (item) => item.area_id === this.area
      )
      const findComponent = findArea.components.find(
        (item) => item.component_name === dataComponentName
      )
      const findDetailWorker = findComponent.workers.find(
        (item) =>
          item.skill_name === dataSkillName &&
          item.detail_component_id === detailComponentId
      )
      const findScheduleDay = findDetailWorker.schedule.find(
        (item) => item.day === dataScheduleDay
      )
      const selected =
        this.$refs[
          'schedule_' +
            dataAreaName +
            '_' +
            dataComponentName +
            '_' +
            dataSkillName +
            '_' +
            detailComponentId +
            '_' +
            dataScheduleDay +
            '_' +
            index
        ][0].selected
      if (findScheduleDay) {
        findScheduleDay.selected = selected
        this.$store.commit(
          'project/setDataJadwalPekerjaan',
          this.dataScheduleWorker
        )
      }
    },
    isAllEqualSchedule (data) {
      const allEqual = data.every(
        (val) =>
          val.selected === 'Tersedia' || val.selected === null || !val.selected
      )
      return allEqual
    },
    handleEqualSchedule (arr) {
      for (const [indexArea, itemArea] of arr.entries()) {
        for (const [indexComp, itemComp] of itemArea.components.entries()) {
          for (const [
            indexWorkers,
            itemWorkers
          ] of itemComp.workers.entries()) {
            if (itemWorkers.skill_id) {
              const isEqual = this.isAllEqualSchedule(itemWorkers.schedule)
              const newObj = {
                area: indexArea,
                component: indexComp,
                workers: indexWorkers
              }
              const found = this.validateForm.some(
                (o) =>
                  o.area === indexArea &&
                  o.component === indexComp &&
                  o.workers === indexWorkers
              )
              const idx = this.validateForm.findIndex(
                (x) =>
                  x.area === indexArea &&
                  x.component === indexComp &&
                  x.workers === indexWorkers
              )
              if (itemWorkers.max !== 'Optional') {
                if (isEqual) {
                  if (found) {
                    this.validateForm[idx] = newObj
                  } else {
                    this.validateForm.push(newObj)
                  }
                } else {
                  if (this.validateForm.length < 1) {
                    this.$emit('isCompleteValidate', true)
                  } else if (found) {
                    this.validateForm.splice(idx, 1)
                  }
                }
              }
            }
          }
        }
      }
    },
    handleIsChange () {
      this.$emit('isChange', true)
    },
    handleMaxDay (indexArea, indexComponent, indexWorker, max, arrSelected) {
      const arr = []
      for (const item of arrSelected) {
        if (
          item.selected &&
          item.selected !== 'Tersedia' &&
          item.selected !== 'Booking'
        ) {
          arr.push(item.day)
        }
      }
      this.dataScheduleWorker[indexArea].components[indexComponent].workers[
        indexWorker
      ].day = arr
      this.setDataScheduleTemp()
    },
    handleMaxDisabled (arr, day, max) {
      if (arr && arr.length > 0) {
        const find = arr.find((x) => x === day)
        if (!find && arr.length === max) {
          return true
        }
        return false
      } else {
        return false
      }
    },
    handleIsFillForm (index) {
      if (this.validateForm.find((e) => e.area === index)) {
        return true
      } else {
        return false
      }
    },
    handleValidateForm (indexArea, indexComp) {
      if (
        this.validateForm.find(
          (e) => e.area === indexArea && e.component === indexComp
        )
      ) {
        return true
      } else {
        return false
      }
    },
    addGroup (
      areaId,
      componentId,
      detailComponentId,
      skillId,
      isChecked,
      skillName,
      skillImage,
      itemWorking,
      workerIndex
    ) {
      this.floatingGroup = true
      this.addGroupMode = true

      const r = Math.floor(Math.random() * 255)
      const g = Math.floor(Math.random() * 255)
      const b = Math.floor(Math.random() * 255)

      let hex = `${r.toString(16)}${g.toString(16)}${b.toString(16)}`

      if (hex.length < 6) {
        hex = `#${hex}1`
      } else {
        hex = `#${hex}`
      }

      this.group.group_color = hex

      if (isChecked === true) {
        this.group.group.push({
          area_id: areaId,
          component_id: componentId,
          detail_component_id: detailComponentId,
          worker_index: workerIndex,
          skill_id: skillId,
          skill_name: skillName,
          skill_image: skillImage,
          note_item_working: itemWorking
        })
      } else {
        let index = -1

        for (let i = 0; i < this.group.group.length; i++) {
          if (
            this.group.group[i].area_id === areaId &&
            this.group.group[i].component_id === componentId &&
            this.group.group[i].detail_component_id === detailComponentId &&
            this.group.group[i].skill_id === skillId
          ) {
            index = i
            break
          }
        }

        if (index !== -1) {
          this.group.group.splice(index, 1)
        }

        if (this.group.group.length < 1) {
          this.addGroupMode = false
        }
      }
    },
    postGroup () {
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        data: this.group
      }

      if (this.modeEditGroup.on === false) {
        this.$swal({
          imageUrl: imageDeleteGroup,
          imageAlt: 'image delete area component',
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Kombinasi Keahlian?</p>',
          html: '<p>Keahlian - keahlian ini akan dikerjakan oleh 1 mitra yang sama</p>',
          showCancelButton: true,
          confirmButtonText: 'Simpan',
          cancelButtonText: 'Cek Kembali',
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch('project/postGrouping', payload)
              .then(response => {
                this.$buefy.toast.open({
                  message: 'Success grouping',
                  type: 'is-success'
                })
                this.group.group = []
                this.floatingGroup = false
                this.addGroupMode = false
                this.getDataJadwalPekerjaan()
              })
              .catch(error => {
                console.log(error)
                this.$buefy.toast.open({
                  message: 'Failed grouping',
                  type: 'is-danger'
                })
                this.group.group = []
                this.floatingGroup = false
                this.addGroupMode = false
                this.getDataJadwalPekerjaan()
              })
          } else {
          }
        })
      } else {
        this.$swal({
          imageUrl: imageDeleteGroup,
          imageAlt: 'image delete area component',
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Ubah Kombinasi Keahlian?</p>',
          html: '<p>Keahlian - keahlian ini akan dikerjakan oleh 1 mitra yang sama</p>',
          showCancelButton: true,
          confirmButtonText: 'Simpan',
          cancelButtonText: 'Cek Kembali',
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch('project/updateGrouping', payload)
              .then(response => {
                this.$buefy.toast.open({
                  message: 'Success update grouping',
                  type: 'is-success'
                })
                this.group.group = []
                this.floatingGroup = false
                this.modeEditGroup.on = false
                this.getDataJadwalPekerjaan()
              })
              .catch(error => {
                console.log(error)
                this.$buefy.toast.open({
                  message: 'Failed update grouping',
                  type: 'is-danger'
                })
                this.group.group = []
                this.floatingGroup = false
                this.modeEditGroup.on = false
                this.getDataJadwalPekerjaan()
              })
          } else {
          }
        })
      }
    },
    editGrouping (
      indexWorker,
      areaId,
      componentId,
      detailComponentId,
      itemWorking
    ) {
      if (this.addGroupMode === true) {
        this.$buefy.toast.open({
          message: 'Tidak bisa dipilih',
          type: 'is-danger'
        })
      } else {
        this.editGroup.modal = !this.editGroup.modal
        this.editGroup.index_worker = indexWorker
        this.editGroup.area_id = areaId
        this.editGroup.component_id = componentId
        this.editGroup.detail_component_id = detailComponentId
        this.editGroup.item_working = itemWorking
      }
    },
    hideGroup () {
      this.editGroup.modal = false
      this.editGroup.index_worker = null
      this.editGroup.area_id = null
      this.editGroup.component_id = null
      this.editGroup.detail_component_id = null
      this.editGroup.item_working = null
    },
    ubahGrouping (groupColor) {
      this.group.survey_id = this.$route.params.surveyId
      this.group.group_color = groupColor

      this.dataScheduleWorker.forEach((dataArea) => {
        dataArea.components.forEach((dataComponent) => {
          dataComponent.workers.forEach((dataWorker) => {
            if (dataWorker.group_color === groupColor) {
              this.group.group.push({
                area_id: dataArea.area_id,
                component_id: dataComponent.component_id,
                detail_component_id: dataWorker.detail_component_id,
                skill_id: dataWorker.skill_id,
                worker_index: dataWorker.worker_index,
                skill_name: dataWorker.skill_name,
                skill_image: dataWorker.skill_image,
                note_item_working: dataWorker.item_working
              })
            }
          })
        })
      })

      this.floatingGroup = true
      this.editGroup.modal = false
      this.modeEditGroup.on = true
      this.modeEditGroup.current_color = groupColor
    },
    changeGroup (
      areaId,
      componentId,
      detailComponentId,
      skillId,
      isChecked,
      skillName,
      skillImage,
      itemWorking,
      currentColor,
      workerIndex
    ) {
      this.group.survey_id = this.$route.params.surveyId

      if (isChecked === true) {
        this.group.group.push({
          area_id: areaId,
          component_id: componentId,
          detail_component_id: detailComponentId,
          worker_index: workerIndex,
          skill_id: skillId,
          skill_name: skillName,
          skill_image: skillImage,
          note_item_working: itemWorking
        })
      } else {
        let index = -1

        for (let i = 0; i < this.group.group.length; i++) {
          if (
            this.group.group[i].area_id === areaId &&
            this.group.group[i].component_id === componentId &&
            this.group.group[i].detail_component_id === detailComponentId &&
            this.group.group[i].skill_id === skillId
          ) {
            index = i
            break
          }
        }

        if (index !== -1) {
          this.group.group.splice(index, 1)
        }

        if (this.group.group.length < 1) {
          this.$swal({
            imageUrl: imageDeleteGroup,
            imageAlt: 'image delete area component',
            width: '600px',
            padding: '10px 10px 30px 10px',
            title: '<p class="averta-bold label-22">Lepas Kombinasi Keahlian?</p>',
            html: '<p>Keahlian yang sudah dikombinasikan akan dihapus</p>',
            showCancelButton: true,
            confirmButtonText: 'Proses',
            cancelButtonText: 'Batalkan',
            customClass: {
              confirmButton: 'btn-hapus-detail-component',
              cancelButton: 'btn-batal-detail-component'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              const payload = {
                url: this.url,
                survey_id: this.$route.params.surveyId,
                data: {
                  group_color: currentColor
                }
              }

              this.$store
                .dispatch('project/removeGrouping', payload)
                .then(response => {
                  this.$buefy.toast.open({
                    message: 'Success remove grouping',
                    type: 'is-success'
                  })
                  this.group.group = []
                  this.floatingGroup = false
                  this.modeEditGroup.on = false
                  this.getDataJadwalPekerjaan()
                })
                .catch(error => {
                  console.log(error)
                  this.$buefy.toast.open({
                    message: 'Failed remove grouping',
                    type: 'is-danger'
                  })
                  this.group.group = []
                  this.floatingGroup = false
                  this.modeEditGroup.on = false
                  this.getDataJadwalPekerjaan()
                })
            } else {
              this.dataScheduleWorker.forEach((dataArea) => {
                dataArea.components.forEach((dataComponent) => {
                  dataComponent.workers.forEach((dataWorker) => {
                    if (dataWorker.group_color === currentColor && dataWorker.checked === false) {
                      dataWorker.checked = true
                    }
                  })
                })
              })
              this.modeEditGroup.on = false
            }
          })
        }
      }
    },
    removeCombine (currentColor) {
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        data: {
          group_color: currentColor
        }
      }

      this.$swal({
        imageUrl: imageDeleteGroup,
        imageAlt: 'image delete area component',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lepas Kombinasi Keahlian?</p>',
        html: '<p>Keahlian yang sudah dikombinasikan akan dihapus</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch('project/removeGrouping', payload)
            .then(response => {
              this.$buefy.toast.open({
                message: 'Success remove grouping',
                type: 'is-success'
              })
              this.getDataJadwalPekerjaan()
            })
            .catch(error => {
              console.log(error)
              this.$buefy.toast.open({
                message: 'Failed grouping',
                type: 'is-danger'
              })
            })
          this.editGroup.modal = false
        } else {
          this.editGroup.modal = false
        }
      })
    },
    lihatKombinasi (currentColor) {
      this.seeCombine.on = true
      this.seeCombine.color = currentColor
      this.seeCombine.rgba = this.convertHex(currentColor)
      this.editGroup.modal = false
    },
    backgroundViewGroup (dataSeeCombine, currentColor) {
      if (dataSeeCombine.on === true) {
        if (dataSeeCombine.color === currentColor) {
          return `{
            border: 1px solid ${dataSeeCombine.color}
          }`
        }
      }
      return ''
    },
    convertHex (color) {
      color = color.replace('#', '')
      const r = parseInt(color.substring(0, 2), 16)
      const g = parseInt(color.substring(2, 4), 16)
      const b = parseInt(color.substring(4, 6), 16)
      return `rgba(${r}, ${g}, ${b}, 0.14)`
    },
    closeCombine () {
      this.seeCombine.on = false
      this.seeCombine.color = null
      this.seeCombine.rgba = null
    },
    setDataScheduleTemp  () {
      this.dataScheduleTemp = this.dataScheduleWorker
    }
  },
  created () {
    this.getDataJadwalPekerjaan()
    if (this.dataScheduleTemp.length > 0) {
      this.dataScheduleTemp = []
    }
  },
  watch: {
    dataScheduleWorker: {
      handler: function (newVal) {
        if (newVal || newVal > 0) {
          if (this.user.roles[0].name === 'Site Manager') {
            this.handleIsChange()
          }
          this.handleEqualSchedule(newVal)
        }
      },
      deep: true
    },
    validateForm (newVal) {
      if (newVal.length === 0) {
        this.$emit('isCompleteValidate', true)
      } else {
        this.$emit('isCompleteValidate', false)
      }
    },
    messageJadwalPekerjaan (newVal) {
      console.log(newVal)
    }
  }
}
</script>

<style scoped>
.wrapper-jadwal-kerja {
  margin-bottom: 200px;
}

.white-space-normal {
  white-space: normal;
}

.attention-tukang-mobile {
  display: none;
}

.attention-tukang-web {
  display: block;
}

.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
}

.border-right {
  border-right: 1px solid #e1e1e1;
}

.wrapper-table {
  overflow-y: auto;
}

.wrapper-table table,
tr,
td {
  border: 1px solid #e1e1e1;
}

.background-td {
  background: #fafafa;
}

.wrapper-workers {
  width: 320px;
  height: 100%;
}

.background-konsultan {
  background: rgba(252, 146, 49, 0.1);
}

.image-skill {
  width: 40px;
}

.backgroundDropdown {
  background: #ebf3ff;
}

.backgroundDropdownBooking {
  background: #e1e1e1;
}

.area-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0px 12px;
}

.alert-icon-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
}

.inline-flex {
  display: inline-flex;
}

.floating-grouping {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  z-index: 99;
}

.floating-top {
  background: var(--gradient-red-red, linear-gradient(90deg, #891313 0%, #d92727 100%));
  padding: 18px 42px;
  border-radius: 24px 24px 0px 0px;
  color: white !important;
  position: relative;
  overflow: hidden;
}

.wave {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
}

.floating-bottom {
  background: white;
  padding: 24px 24px;
}

.wrapper-skill {
  background: white !important;
  border: 1px solid #e1e1e1;
  z-index: 1;
  border-radius: 12px 12px;
  padding: 8px 24px;
  overflow-x: auto;
}

.btn-grouping {
  border: none;
  padding: 12px 24px !important;
  background: #9a9a9a;
  color: white;
  border-radius: 24px;
}

.edit-group {
  position: absolute;
  left: 40px;
  top: 30px;
  width: 18rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
}

.header-edit-kombinasi {
  background: #e1e1e1;
  border-radius: 12px 12px 0px 0px;
}

.btn-grouping.active {
  background: var(
    --gradient-red-red-primary-button,
    linear-gradient(90deg, #d9272d 0%, #eb4600 99.99%)
  );
}

@media only screen and (min-width: 768px) {
  .area-wrapper {
    max-height: 65vh;
    overflow: auto;
  }
}

@media only screen and (max-width: 768px) {
  .attention-tukang-mobile {
    display: block;
  }

  .attention-tukang-web {
    display: none;
  }

  .area-wrapper {
    white-space: nowrap;
    overflow-x: scroll;
  }

  .border-right {
    border: none;
  }

  .area-wrapper {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .area-wrapper::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .btn-tabs {
    padding-right: 3rem;
    padding-left: 3rem;
    margin-bottom: 0px !important;
  }

  .white-space-sm-nowrap {
    white-space: nowrap;
  }

  .tab-content-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .tab-content-wrapper .b-tabs .tab-content {
    padding: 0 !important;
  }

  .b-tabs .tab-content {
    padding: 0 !important;
  }

  .px-sm-3 {
    padding: 0 20px;
  }

  .floating-top {
    padding: 12px 24px;
  }

  .grouping-icon {
    width: 80px !important;
    height: 80px !important;
  }

  .floating-bottom {
    display: block !important;
  }

  .btn-grouping {
    margin-top: 12px;
    width: 100%;
    justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .alert-icon-wrapper {
    position: relative !important;
    top: 0;
    right: 0;
    margin-left: 0.5rem;
  }
}
</style>
